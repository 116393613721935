// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      //
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
       let maxSupply = await store
         .getState()
        .blockchain.smartContract.methods.supplyLimit()
         .call();
         let account = await store
         .getState()
         .blockchain.account;  

      //   
    
       //   
       let publicsupplyLimit = await store
       .getState()
      .blockchain.smartContract.methods.supplyLimit()
       .call();
       let publicprice = await store
       .getState()
      .blockchain.smartContract.methods.price()
       .call();
       let publicMintCount = await store
       .getState()
      .blockchain.smartContract.methods.publicMintCount(account)
       .call();
        let publicSaleStatus = await store
       .getState()
      .blockchain.smartContract.methods.publicSale()
       .call();
       let publicMinted = await store
        .getState()
       .blockchain.smartContract.methods.publicMinted()
        .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          maxSupply,
          //
          //

          publicsupplyLimit,
          publicprice,
          publicMintCount,
          publicSaleStatus,
          publicMinted,

        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
