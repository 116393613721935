import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './App.css';
import { connect } from './redux/blockchain/blockchainActions'
import { fetchData } from './redux/data/dataActions'


function App() {

	const [feedback, setFeedback] = useState(``)

	const dispatch = useDispatch()
	const blockchain = useSelector((state) => state.blockchain)
	const data = useSelector((state) => state.data)
	const [claimingNft, setClaimingNft] = useState(false)
	const [mintAmount, setMintAmount] = useState(1)
	const [CONFIG, SET_CONFIG] = useState({
		NFTNAME: "SIGNATURE",
		CONTRACT_ADDRESS: "0x0000000000000000000000000000000000000000",
		NETWORK: "Ethereum",
		ID: 1,
		MARKETPLACE: "Opeansea",
		TWITTER: "https://twitter.com/",
		ETHERSCAN: "https://etherscan.io/",
		DISCORD: "https://discord.com/",
		MARKETPLACE_LINK: "https://opensea.io/"
	})
	var button;
	var connectbutton;
	if (blockchain.account === '' ||
		blockchain.smartContract === null) {
		connectbutton = <React.Fragment>
			<div id="connect" className="btn alink r" onClick={(e) => {
				e.preventDefault()
				dispatch(connect())
				getData()
			}}>
				<div> Connect Wallet</div></div>
		</React.Fragment>
	}

	else {
		connectbutton = <React.Fragment>
			<div id="connect" className="btn alink r">
				<div>Connected</div>
			</div>
		</React.Fragment>
	}


	if (blockchain.account === '' ||
		blockchain.smartContract === null) {
		button = <React.Fragment>
			<a className="btn reg  w-button"
				onClick={(e) => {
					e.preventDefault()
					dispatch(connect())
					getData()
				}}> Connect</a>
		</React.Fragment>
	}
	else if ((data.publicprice) == 0 && blockchain.smartContract !== null) {
		button = <React.Fragment>
			<a className="btn reg  w-button">{'Loading'} </a>
		</React.Fragment>
	}
	else if (Number(data.totalSupply) >= Number(data.maxSupply)) {
		button = <React.Fragment>
			<a href={CONFIG.MARKETPLACE_LINK} target={'_blank'} className="btn reg  w-button">
				Soldout!
			</a>
		</React.Fragment>
	}

	else if ((data.publicSaleStatus) == true) {
		button = <React.Fragment>
			<a
				disabled={claimingNft ? 1 : 0}
				onClick={(e) => {
					PublicclaimNFTs()


					getData()
				}}
				className="btn reg  w-button">
				{claimingNft ? 'BUSY' : 'Purchase Artwork'}
			</a>
		</React.Fragment>
	}
	else {
		button = <React.Fragment>
			<a className="btn reg  w-button">{'Sale Not Active'} </a>

		</React.Fragment>
	}

	const getData = () => {
		if (blockchain.account !== '' && blockchain.smartContract !== null) {
			dispatch(fetchData(blockchain.account))
		}
	}

	useEffect(() => {
		getData((data.totalSupply))
	}, [blockchain.account])

	const getConfig = async () => {
		const configResponse = await fetch('/config/config.json', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		})
		const config = await configResponse.json()
		SET_CONFIG(config)
	}

	useEffect(() => {
		getConfig()
	}, [])


	const PublicclaimNFTs = () => {
		let cost = (data.publicprice)
		let totalCostWei = String(cost * mintAmount)
		console.log('Cost: ', totalCostWei)
		console.log(cost)
		setFeedback(`Minting your ${CONFIG.NFTNAME}...`)
		setClaimingNft(true)
		blockchain.smartContract.methods
			.PublicMint()
			.send({
				gasLimit: 210000,
				maxPriorityFeePerGas: null,
				maxFeePerGas: null,
				to: CONFIG.CONTRACT_ADDRESS,
				from: blockchain.account,
				value: totalCostWei,
			})
			.once('error', (err) => {
				console.log(err)
				setFeedback(err.message)
				setClaimingNft(false)
			})
			.then((receipt) => {
				console.log(receipt)
				setFeedback(
					`The ${CONFIG.NFTNAME} is yours! go visit ${CONFIG.MARKETPLACE} to view it.`,
				)
				setClaimingNft(false)
				dispatch(fetchData(blockchain.account))
			})
	}



	return (
		<main classNameName="App">
			<div className="scripts w-embed">
			</div>
			<div className="section-nav wf-section">
				<div className="overlay-navbar"></div>
				<div data-w-id="2311c60d-6a13-c369-a330-1f2331378cfd" data-animation="default" data-collapse="all"
					data-duration="20" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
					<div className="nav-container">
						<div className="wrapper-nav-left">
							<div className="menu-button w-nav-button">
								<div data-is-ix2-target="1" className="menubutton" data-w-id="2311c60d-6a13-c369-a330-1f2331378d01"
									data-animation-type="lottie"
									data-src="https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/63340465d35902fb53d35e4d_menu-b.json"
									data-loop="0" data-direction="1" data-autoplay="0" data-renderer="svg"
									data-default-duration="2.5" data-duration="0" data-ix2-initial-state="0"></div>
							</div>
							<nav role="navigation" className="nav-menu w-nav-menu"><img sizes="(max-width: 479px) 0px, 100vw"
								srcSet="https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306844ebd5a4b6f6b21_LOGO%20(2)-p-500.png 500w, https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306844ebd5a4b6f6b21_LOGO%20(2)-p-800.png 800w, https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306844ebd5a4b6f6b21_LOGO%20(2)-p-1080.png 1080w, https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306844ebd5a4b6f6b21_LOGO%20(2)-p-1600.png 1600w, https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306844ebd5a4b6f6b21_LOGO%20(2)-p-2000.png 2000w, https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306844ebd5a4b6f6b21_LOGO%20(2)-p-2600.png 2600w, https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306844ebd5a4b6f6b21_LOGO%20(2)-p-3200.png 3200w"
								src="https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306844ebd5a4b6f6b21_LOGO%20(2).png"
								loading="lazy" alt="" className="img-nav" /><a href="/artists"
									className="navlink w-nav-link">Artists</a><a href="https://www.digitalartclub.io/artist/pam-su"
										className="navlink w-nav-link">on now</a><a href="/club" className="navlink w-nav-link">club</a><a
											href="/gallery" className="navlink w-nav-link">gallery</a><a href="/about"
												className="navlink w-nav-link">about</a><a href="/news" className="navlink w-nav-link">news
													&amp; press</a><a href="/submit-work" className="navlink w-nav-link">submit work</a><a
														href="/contact" className="navlink w-nav-link">contact</a>
								<div className="separator-nav">
									<div className="wrapper-sm"><a href="https://twitter.com/digitalart_club?s=11" target="_blank"
										className="sm-item w-inline-block"><img loading="lazy"
											src="https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62fa15758ff44326b9977718_twitter.svg"
											alt="" className="img-social" /></a><a href="https://discord.gg/nEQyFEfmaC"
												target="_blank" className="sm-item l w-inline-block"><img loading="lazy"
													src="https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62fa1575943e1b347c143276_discord.svg"
													alt="" className="img-social" /></a><a
														href="https://www.instagram.com/dac_digitalartclub" target="_blank"
														className="sm-item w-inline-block"><img loading="lazy"
															src="https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62fa1575071b679242e59e05_instagram.svg"
															alt="" className="img-social" /></a></div>
								</div>
							</nav>
						</div><a href="/" className="brand w-nav-brand"><img sizes="(max-width: 767px) 90px, 120px"
							srcSet="https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306f9b93fbd25c3211b_LOGO%20(3)-p-500.png 500w, https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306f9b93fbd25c3211b_LOGO%20(3)-p-800.png 800w, https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306f9b93fbd25c3211b_LOGO%20(3)-p-1080.png 1080w, https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306f9b93fbd25c3211b_LOGO%20(3)-p-1600.png 1600w, https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306f9b93fbd25c3211b_LOGO%20(3)-p-2000.png 2000w, https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306f9b93fbd25c3211b_LOGO%20(3)-p-2600.png 2600w, https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306f9b93fbd25c3211b_LOGO%20(3)-p-3200.png 3200w"
							src="https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306f9b93fbd25c3211b_LOGO%20(3).png"
							loading="lazy" alt="" className="img-logo alink" /></a>
						<div className="wrapper-nav-right">
							{connectbutton}
						</div>
					</div>
				</div>
			</div>
			<div className="page-wrapper nopadding">
				<div className="section pad-top nopad-sides wf-section">
					<div className="container">
						<div className="wrapper c botm">
							<div className="content _45">
								<h1 className="section-title">PAM SU</h1>
								<h2 className="section-title al-r _50">&quot;Space Dogs &amp; Loud Colour&quot;</h2>
								<p className="p-reg small">Pam Su graduated from the Royal College of Art in 2018 and works in the
									field of ceramics as well as mixed media art. Her experimental sculptures capture the
									disillusionment hidden beneath the use of colours, texture, scale and fluidity. For Su’
									inaugural works, she has created 10 exclusive ceramic pieces for D.A.C. and 100 unique NFTs
									available for sale, placing 10 hidden keys to unlocking and granting ownership of the
									physical ceramic sculpture.</p>
									<p className="p-reg small">{feedback}</p>
								<div className="wrapper-more-info">
									<div className="wrapper-details">
										<div className="item-detail"><img
											src="https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/638db340c52f5450aa519864_001-ethereum.svg"
											loading="lazy" alt="" className="icon-s" />
											<div>0.5 ETH</div>
										</div>
										<div className="item-detail"><img
											src="https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/638db341ce20b790a6d7eb64_002-cube-with-layers.svg"
											loading="lazy" alt="" className="icon-s" />
											<div>{data.maxSupply-data.totalSupply}</div>
										</div>
										
									</div>
									<div className="wrapper-buttons-more-info">{button}</div>
								</div>
							</div>
							<div className="content _45">
								<div className="wrapper-project"><img loading="lazy"
									src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/635a1dc1989f0f84f3a92662_63343e0945a703a08b4f85a8_PAM%20(2).jpg"
									alt=""
									sizes="(max-width: 479px) 100vw, (max-width: 767px) 97vw, (max-width: 991px) 98vw, 45vw"
									srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/635a1dc1989f0f84f3a92662_63343e0945a703a08b4f85a8_PAM%20(2)-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/635a1dc1989f0f84f3a92662_63343e0945a703a08b4f85a8_PAM%20(2)-p-800.jpg 800w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/635a1dc1989f0f84f3a92662_63343e0945a703a08b4f85a8_PAM%20(2).jpg 1000w"
									className="img-project medium" />
									<div className="mint-start-wrapper">
										<div id="js-clock" className="js-clock">
											<div className="box">
												<div id="js-clock-seconds" className="clock-number"> Minting</div>
												<div className="clock-label">Live Now</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="video-full w-video w-embed"><iframe className="embedly-embed"
						src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FgoVRoCIkEzc%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DgoVRoCIkEzc&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FgoVRoCIkEzc%2Fhqdefault.jpg&key=c4e54deccf4d4ec997a64902e9a30300&type=text%2Fhtml&schema=youtube"
						width="854" height="480" scrolling="no" title="YouTube embed" frameBorder="0"
						allow="autoplay; fullscreen" allowFullScreen="true"></iframe></div>
					<div className="container">
						<div className="content"></div>
						<div className="content">
							<div className="w-dyn-list">
								<div role="list" className="grid-3-x w-dyn-items">
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy" alt=""
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d70c97d6fa1ab721ce84_Mango%20Siphon%201.jpg"
											className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">1/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d71374a2644efb125506_Mango%20Siphon%203-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d71374a2644efb125506_Mango%20Siphon%203.jpg 506w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d71374a2644efb125506_Mango%20Siphon%203.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">2/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy" alt=""
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d71c9a7c8116477e20e9_Mango%20Siphon%204.jpg"
											className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">3/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d7233032215ee0d0bb15_Mango%20Siphon%205-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d7233032215ee0d0bb15_Mango%20Siphon%205.jpg 506w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d7233032215ee0d0bb15_Mango%20Siphon%205.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">4/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d72830322103f2d0bb54_Mango%20Siphon%206-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d72830322103f2d0bb54_Mango%20Siphon%206.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d72830322103f2d0bb54_Mango%20Siphon%206.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">5/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d72e844a174ad7e69633_Mango%20Siphon%207-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d72e844a174ad7e69633_Mango%20Siphon%207.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d72e844a174ad7e69633_Mango%20Siphon%207.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">6/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d7342af4933d5eaa61e1_Mango%20Siphon%208-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d7342af4933d5eaa61e1_Mango%20Siphon%208.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d7342af4933d5eaa61e1_Mango%20Siphon%208.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">7/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d74d0ea4090d041d4e55_Mango%20Siphon%2010-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d74d0ea4090d041d4e55_Mango%20Siphon%2010.jpg 508w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d74d0ea4090d041d4e55_Mango%20Siphon%2010.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">8/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d74777693836eec603b4_Mango%20Siphon%209-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d74777693836eec603b4_Mango%20Siphon%209.jpg 506w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d74777693836eec603b4_Mango%20Siphon%209.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">9/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d776d5a9054131de5eeb_Mango%20Siphon-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d776d5a9054131de5eeb_Mango%20Siphon-p-800.jpg 800w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d776d5a9054131de5eeb_Mango%20Siphon-p-1080.jpg 1080w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d776d5a9054131de5eeb_Mango%20Siphon-p-1600.jpg 1600w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d776d5a9054131de5eeb_Mango%20Siphon-p-2000.jpg 2000w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d776d5a9054131de5eeb_Mango%20Siphon-p-2600.jpg 2600w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d776d5a9054131de5eeb_Mango%20Siphon-p-3200.jpg 3200w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d776d5a9054131de5eeb_Mango%20Siphon.jpg 5047w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d776d5a9054131de5eeb_Mango%20Siphon.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">10/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6ff0ea40950ac1d4bf1_Soap%20Eyes%206-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6ff0ea40950ac1d4bf1_Soap%20Eyes%206.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6ff0ea40950ac1d4bf1_Soap%20Eyes%206.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">11/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6f8fd0a0382539d4ca4_Soap%20Eyes%205-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6f8fd0a0382539d4ca4_Soap%20Eyes%205.jpg 506w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6f8fd0a0382539d4ca4_Soap%20Eyes%205.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">12/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6f1c67ea338b2cb7c27_Soap%20Eyes%203-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6f1c67ea338b2cb7c27_Soap%20Eyes%203.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6f1c67ea338b2cb7c27_Soap%20Eyes%203.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">13/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6ea303221671ad0b7d6_Soap%20Eyes%202-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6ea303221671ad0b7d6_Soap%20Eyes%202.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6ea303221671ad0b7d6_Soap%20Eyes%202.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">14/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6e42af4931b59aa5e79_Soap%20Eyes%201-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6e42af4931b59aa5e79_Soap%20Eyes%201.jpg 538w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6e42af4931b59aa5e79_Soap%20Eyes%201.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">15/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6dd2af493a129aa5e52_Soap%20Eyes%207-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6dd2af493a129aa5e52_Soap%20Eyes%207.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6dd2af493a129aa5e52_Soap%20Eyes%207.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">16/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6d6844a174b69e69446_Soap%20Eyes%208-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6d6844a174b69e69446_Soap%20Eyes%208.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6d6844a174b69e69446_Soap%20Eyes%208.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">17/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6cbe4d348572fa2f5cf_Soap%20Eyes-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6cbe4d348572fa2f5cf_Soap%20Eyes-p-800.jpg 800w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6cbe4d348572fa2f5cf_Soap%20Eyes-p-1080.jpg 1080w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6cbe4d348572fa2f5cf_Soap%20Eyes-p-1600.jpg 1600w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6cbe4d348572fa2f5cf_Soap%20Eyes-p-2000.jpg 2000w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6cbe4d348572fa2f5cf_Soap%20Eyes-p-2600.jpg 2600w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6cbe4d348572fa2f5cf_Soap%20Eyes-p-3200.jpg 3200w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6cbe4d348572fa2f5cf_Soap%20Eyes.jpg 4572w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6cbe4d348572fa2f5cf_Soap%20Eyes.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">18/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6c3e23b70bf0759446e_Soap%20Eyes%209-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6c3e23b70bf0759446e_Soap%20Eyes%209.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6c3e23b70bf0759446e_Soap%20Eyes%209.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">19/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6bdcb2653ba6c68d13a_Soap%20Eyes%2010-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6bdcb2653ba6c68d13a_Soap%20Eyes%2010.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d6bdcb2653ba6c68d13a_Soap%20Eyes%2010.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">20/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d485e8724a3d2b3f333d_Vagabond%20Blue%2010-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d485e8724a3d2b3f333d_Vagabond%20Blue%2010.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d485e8724a3d2b3f333d_Vagabond%20Blue%2010.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">21/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d48006dce22f736c69ee_Vagabond%20Blue%209-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d48006dce22f736c69ee_Vagabond%20Blue%209.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d48006dce22f736c69ee_Vagabond%20Blue%209.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">22/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d47be23b709d34591ffd_Vagabond%20Blue%208-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d47be23b709d34591ffd_Vagabond%20Blue%208.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d47be23b709d34591ffd_Vagabond%20Blue%208.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">23/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d471c5201260795f6c25_Vagabond%20Blue%207-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d471c5201260795f6c25_Vagabond%20Blue%207.jpg 506w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d471c5201260795f6c25_Vagabond%20Blue%207.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">24/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d46c7769389cf3c5e276_Vagabond%20Blue%206-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d46c7769389cf3c5e276_Vagabond%20Blue%206.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d46c7769389cf3c5e276_Vagabond%20Blue%206.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">25/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d465777519063dfb1e57_Vagabond%20Blue%205-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d465777519063dfb1e57_Vagabond%20Blue%205.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d465777519063dfb1e57_Vagabond%20Blue%205.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">26/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d45697d6fa479421acb8_Vagabond%20Blue%203-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d45697d6fa479421acb8_Vagabond%20Blue%203.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d45697d6fa479421acb8_Vagabond%20Blue%203.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">28/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy" alt=""
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d45e3bc91e75d99ea098_Vagabond%20Blue%204.jpg"
											className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">27/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy" alt=""
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d44d3439866590b85167_Vagabond%20Blue%202.jpg"
											className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">29/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d442c37f2437a396c76e_Vagabond%20Blue%201-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d442c37f2437a396c76e_Vagabond%20Blue%201.jpg 506w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d442c37f2437a396c76e_Vagabond%20Blue%201.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">30/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3ff06dce24a0e6c60fd_Penrose%20Soda%202-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3ff06dce24a0e6c60fd_Penrose%20Soda%202.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3ff06dce24a0e6c60fd_Penrose%20Soda%202.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">32/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3f9776938da26c5dd49_Penrose%20Soda%203-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3f9776938da26c5dd49_Penrose%20Soda%203.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3f9776938da26c5dd49_Penrose%20Soda%203.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">33/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3f22af4937cb1aa1519_Penrose%20Soda%204-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3f22af4937cb1aa1519_Penrose%20Soda%204.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3f22af4937cb1aa1519_Penrose%20Soda%204.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">34/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3eb777519e4c9fb138f_Penrose%20Soda%205-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3eb777519e4c9fb138f_Penrose%20Soda%205.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3eb777519e4c9fb138f_Penrose%20Soda%205.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">35/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3e5e4d348188aa2ce10_Penrose%20Soda%207-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3e5e4d348188aa2ce10_Penrose%20Soda%207.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3e5e4d348188aa2ce10_Penrose%20Soda%207.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">36/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3dec122e4e03cbe3c59_Penrose%20Soda%208-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3dec122e4e03cbe3c59_Penrose%20Soda%208.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3dec122e4e03cbe3c59_Penrose%20Soda%208.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">37/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3d73032213d7ed0815f_Penrose%20Soda%209-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3d73032213d7ed0815f_Penrose%20Soda%209.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3d73032213d7ed0815f_Penrose%20Soda%209.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">38/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy" alt=""
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3d0c67ea33338cb5829_Penrose%20Soda%2010.jpg"
											className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">39/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3c730a2056d9ab36037_Penrose%20Soda-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3c730a2056d9ab36037_Penrose%20Soda-p-800.jpg 800w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3c730a2056d9ab36037_Penrose%20Soda-p-1080.jpg 1080w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3c730a2056d9ab36037_Penrose%20Soda-p-1600.jpg 1600w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3c730a2056d9ab36037_Penrose%20Soda-p-2000.jpg 2000w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3c730a2056d9ab36037_Penrose%20Soda-p-2600.jpg 2600w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3c730a2056d9ab36037_Penrose%20Soda-p-3200.jpg 3200w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3c730a2056d9ab36037_Penrose%20Soda.jpg 5158w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3c730a2056d9ab36037_Penrose%20Soda.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">40/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3b32af4937049aa1010_Lost%20and%20Found%2010-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3b32af4937049aa1010_Lost%20and%20Found%2010.jpg 538w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3b32af4937049aa1010_Lost%20and%20Found%2010.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">41/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3ac2af493193faa0fe2_Lost%20and%20Found%209-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3ac2af493193faa0fe2_Lost%20and%20Found%209.jpg 538w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3ac2af493193faa0fe2_Lost%20and%20Found%209.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">42/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3a6e4d348638aa2ca31_Lost%20and%20Found%208a-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3a6e4d348638aa2ca31_Lost%20and%20Found%208a.jpg 536w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3a6e4d348638aa2ca31_Lost%20and%20Found%208a.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">43/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3a0e4d348b18ba2c9d7_Lost%20and%20Found%207-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3a0e4d348b18ba2c9d7_Lost%20and%20Found%207.jpg 542w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3a0e4d348b18ba2c9d7_Lost%20and%20Found%207.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">44/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3980ea4098cad1d1488_Lost%20and%20Found%206(1)-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3980ea4098cad1d1488_Lost%20and%20Found%206(1).jpg 538w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3980ea4098cad1d1488_Lost%20and%20Found%206(1).jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">45/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d393343986e783b84c21_Lost%20and%20Found%205-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d393343986e783b84c21_Lost%20and%20Found%205.jpg 538w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d393343986e783b84c21_Lost%20and%20Found%205.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">46/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d38dfd0a03a5e79d2277_Lost%20and%20Found%204-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d38dfd0a03a5e79d2277_Lost%20and%20Found%204.jpg 540w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d38dfd0a03a5e79d2277_Lost%20and%20Found%204.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">47/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3882af49368deaa0ca0_Lost%20and%20Found%203-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3882af49368deaa0ca0_Lost%20and%20Found%203.jpg 540w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3882af49368deaa0ca0_Lost%20and%20Found%203.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">48/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d382343986a78bb84a80_Lost%20and%20Found%202-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d382343986a78bb84a80_Lost%20and%20Found%202.jpg 540w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d382343986a78bb84a80_Lost%20and%20Found%202.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">49/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d37a97d6fac4ce2190ef_Lost%20and%20Found%201-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d37a97d6fac4ce2190ef_Lost%20and%20Found%201.jpg 542w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d37a97d6fac4ce2190ef_Lost%20and%20Found%201.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">50/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d36c30a205a184b35af7_Ground%20Powder%208-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d36c30a205a184b35af7_Ground%20Powder%208.jpg 506w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d36c30a205a184b35af7_Ground%20Powder%208.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">51/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d36630a2052712b35abd_Ground%20Powder%2010-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d36630a2052712b35abd_Ground%20Powder%2010.jpg 506w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d36630a2052712b35abd_Ground%20Powder%2010.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">52/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d35fc5201252f65f5e90_Ground%20Powder%209-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d35fc5201252f65f5e90_Ground%20Powder%209.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d35fc5201252f65f5e90_Ground%20Powder%209.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">53/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3551d049e198848890c_Ground%20Powder%207-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3551d049e198848890c_Ground%20Powder%207.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d3551d049e198848890c_Ground%20Powder%207.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">54/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy" alt=""
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d34f77693850d1c5cd22_Ground%20Powder%206.jpg"
											className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">55/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d34ac52012616f5f5aa9_Ground%20Powder%205-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d34ac52012616f5f5aa9_Ground%20Powder%205.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d34ac52012616f5f5aa9_Ground%20Powder%205.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">56/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d343e23b704b6159067c_Ground%20Powder%204-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d343e23b704b6159067c_Ground%20Powder%204.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d343e23b704b6159067c_Ground%20Powder%204.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">57/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d33d777519874efb0e25_Ground%20Powder%203-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d33d777519874efb0e25_Ground%20Powder%203.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d33d777519874efb0e25_Ground%20Powder%203.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">58/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d337c9635f4a07ccc5e3_Ground%20Powder%202-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d337c9635f4a07ccc5e3_Ground%20Powder%202.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d337c9635f4a07ccc5e3_Ground%20Powder%202.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">59/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy" alt=""
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d330c5201225305f5899_Ground%20Powder%201.jpg"
											className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">60/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2f8e4d3489136a2bf04_Fruit%20Flies%2010-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2f8e4d3489136a2bf04_Fruit%20Flies%2010.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2f8e4d3489136a2bf04_Fruit%20Flies%2010.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">61/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2f106dce236956c51cc_Fruit%20Flies%209-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2f106dce236956c51cc_Fruit%20Flies%209.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2f106dce236956c51cc_Fruit%20Flies%209.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">62/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2eafd0a0396289d160e_Fruit%20Flies%208-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2eafd0a0396289d160e_Fruit%20Flies%208.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2eafd0a0396289d160e_Fruit%20Flies%208.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">63/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2e44a0ef02e16bbc1f9_Fruit%20Flies%207-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2e44a0ef02e16bbc1f9_Fruit%20Flies%207.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2e44a0ef02e16bbc1f9_Fruit%20Flies%207.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">64/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2dc7775194539fb0c32_Fruit%20Flies%206-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2dc7775194539fb0c32_Fruit%20Flies%206.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2dc7775194539fb0c32_Fruit%20Flies%206.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">65/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2d6c37f248e5596b937_Fruit%20Flies%205-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2d6c37f248e5596b937_Fruit%20Flies%205.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2d6c37f248e5596b937_Fruit%20Flies%205.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">66/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2d0c122e475bebe3431_Fruit%20Flies%204-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2d0c122e475bebe3431_Fruit%20Flies%204.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2d0c122e475bebe3431_Fruit%20Flies%204.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">67/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2cb3439869dd6b8382a_Fruit%20Flies%203-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2cb3439869dd6b8382a_Fruit%20Flies%203.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2cb3439869dd6b8382a_Fruit%20Flies%203.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">68/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2c4844a170875e662fd_Fruit%20Flies%202-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2c4844a170875e662fd_Fruit%20Flies%202.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2c4844a170875e662fd_Fruit%20Flies%202.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">69/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2b930a2054581b355fd_Fruit%20Flies%201-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2b930a2054581b355fd_Fruit%20Flies%201.jpg 506w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2b930a2054581b355fd_Fruit%20Flies%201.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">70/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2a474a26443ae120f6a_Fountain%20Pen%2010-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2a474a26443ae120f6a_Fountain%20Pen%2010.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2a474a26443ae120f6a_Fountain%20Pen%2010.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">71/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d29e3bc91ef11e9e82fc_Fountain%20Pen%209-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d29e3bc91ef11e9e82fc_Fountain%20Pen%209.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d29e3bc91ef11e9e82fc_Fountain%20Pen%209.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">72/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2961d049e975f488054_Fountain%20Pen%208-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2961d049e975f488054_Fountain%20Pen%208.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2961d049e975f488054_Fountain%20Pen%208.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">73/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d290c122e4404bbe2ea8_Fountain%20Pen%207-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d290c122e4404bbe2ea8_Fountain%20Pen%207.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d290c122e4404bbe2ea8_Fountain%20Pen%207.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">74/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d289cb2653c8ee688ce7_Fountain%20Pen%206-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d289cb2653c8ee688ce7_Fountain%20Pen%206.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d289cb2653c8ee688ce7_Fountain%20Pen%206.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">75/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d283f712e3405548cde5_Fountain%20Pen%205-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d283f712e3405548cde5_Fountain%20Pen%205.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d283f712e3405548cde5_Fountain%20Pen%205.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">76/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d27d303221bbf8d06944_Fountain%20Pen%204-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d27d303221bbf8d06944_Fountain%20Pen%204.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d27d303221bbf8d06944_Fountain%20Pen%204.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">77/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d27577693827b4c5c232_Fountain%20Pen%203-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d27577693827b4c5c232_Fountain%20Pen%203.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d27577693827b4c5c232_Fountain%20Pen%203.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">78/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d26f34398607e9b832c3_Fountain%20Pen%202-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d26f34398607e9b832c3_Fountain%20Pen%202.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d26f34398607e9b832c3_Fountain%20Pen%202.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">79/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2650ea40927c41cf587_Fountain%20Pen%201-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2650ea40927c41cf587_Fountain%20Pen%201.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2650ea40927c41cf587_Fountain%20Pen%201.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">80/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d256e4d348e65ba2b6f3_Filter%20Feeder%2010-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d256e4d348e65ba2b6f3_Filter%20Feeder%2010.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d256e4d348e65ba2b6f3_Filter%20Feeder%2010.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">81/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d25058bebd5d8519b716_Filter%20Feeder%209-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d25058bebd5d8519b716_Filter%20Feeder%209.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d25058bebd5d8519b716_Filter%20Feeder%209.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">82/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2473439866949b82f0d_Filter%20Feeder%208-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2473439866949b82f0d_Filter%20Feeder%208.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2473439866949b82f0d_Filter%20Feeder%208.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">83/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d23ec9635f359accb862_Filter%20Feeder%207a-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d23ec9635f359accb862_Filter%20Feeder%207a.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d23ec9635f359accb862_Filter%20Feeder%207a.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">84/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2351d049ec7d3487e44_Filter%20Feeder%206-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2351d049ec7d3487e44_Filter%20Feeder%206.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d2351d049ec7d3487e44_Filter%20Feeder%206.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">85/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d22e58bebddb0519b533_Filter%20Feeder%205-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d22e58bebddb0519b533_Filter%20Feeder%205.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d22e58bebddb0519b533_Filter%20Feeder%205.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">86/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d229458e00c657a9932c_Filter%20Feeder%204-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d229458e00c657a9932c_Filter%20Feeder%204.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d229458e00c657a9932c_Filter%20Feeder%204.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">87/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d223d5a90508d3de1159_Filter%20Feeder%203-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d223d5a90508d3de1159_Filter%20Feeder%203.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d223d5a90508d3de1159_Filter%20Feeder%203.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">88/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d21dd5a905fba7de10a7_Filter%20Feeder%202-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d21dd5a905fba7de10a7_Filter%20Feeder%202.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d21dd5a905fba7de10a7_Filter%20Feeder%202.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">89/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d214fd0a0337889d0b01_Filter%20Feeder%201-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d214fd0a0337889d0b01_Filter%20Feeder%201.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352d214fd0a0337889d0b01_Filter%20Feeder%201.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">90/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf8f06dce288e26c06b5_Elastic%20Sour%2010-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf8f06dce288e26c06b5_Elastic%20Sour%2010.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf8f06dce288e26c06b5_Elastic%20Sour%2010.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">91/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf8b35e7544f2511a162_Elastic%20Sour%209-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf8b35e7544f2511a162_Elastic%20Sour%209.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf8b35e7544f2511a162_Elastic%20Sour%209.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">92/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf86482c555140081454_Elastic%20Sour%208-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf86482c555140081454_Elastic%20Sour%208.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf86482c555140081454_Elastic%20Sour%208.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">93/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf81c52012bf4c5f1b01_Elastic%20Sour%207-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf81c52012bf4c5f1b01_Elastic%20Sour%207.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf81c52012bf4c5f1b01_Elastic%20Sour%207.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">94/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf7bcb26532d7d685243_Elastic%20Sour%206a-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf7bcb26532d7d685243_Elastic%20Sour%206a.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf7bcb26532d7d685243_Elastic%20Sour%206a.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">95/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf7577751905b3fad796_Elastic%20Sour%205-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf7577751905b3fad796_Elastic%20Sour%205.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf7577751905b3fad796_Elastic%20Sour%205.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">96/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf60e6a8ab8bffae807d_Elastic%20Sour%204-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf60e6a8ab8bffae807d_Elastic%20Sour%204.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf60e6a8ab8bffae807d_Elastic%20Sour%204.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">97/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf59c122e4655dbe06ac_Elastic%20Sour%203-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf59c122e4655dbe06ac_Elastic%20Sour%203.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf59c122e4655dbe06ac_Elastic%20Sour%203.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">98/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf525746207b70f19653_Elastic%20Sour%202-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf525746207b70f19653_Elastic%20Sour%202.jpg 502w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf525746207b70f19653_Elastic%20Sour%202.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">99/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
									<div role="listitem" className="w-dyn-item">
										<div className="wrapper-project"><img loading="lazy"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 48vw, 32vw"
											srcSet="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf4bc67ea30119cb0662_Elastic%20Sour%201-p-500.jpg 500w, https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf4bc67ea30119cb0662_Elastic%20Sour%201.jpg 504w"
											src="https://global-uploads.webflow.com/62f4b84ee4391f46a7f4b36e/6352cf4bc67ea30119cb0662_Elastic%20Sour%201.jpg"
											alt="" className="img-project tiny padding" />
											<div className="side-wrapper-project low w">
												<div className="content-side wr">
													<h3 className="title-author l">100/100</h3>
													<h3 className="title-author l _1 w-dyn-bind-empty"></h3>
												</div><a href="#" className="btn ghost dark tiny t w-button">buy</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="section footer wf-section">
				<div className="container">
					<div className="wrapper">
						<div className="content ft-r"><img
							src="https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306844ebd5a4b6f6b21_LOGO%20(2).png"
							loading="lazy" sizes="120px"
							srcSet="https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306844ebd5a4b6f6b21_LOGO%20(2)-p-500.png 500w, https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306844ebd5a4b6f6b21_LOGO%20(2)-p-800.png 800w, https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306844ebd5a4b6f6b21_LOGO%20(2)-p-1080.png 1080w, https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306844ebd5a4b6f6b21_LOGO%20(2)-p-1600.png 1600w, https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306844ebd5a4b6f6b21_LOGO%20(2)-p-2000.png 2000w, https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306844ebd5a4b6f6b21_LOGO%20(2)-p-2600.png 2600w, https://global-uploads.webflow.com/62f4b297270e0c76a6fbf79a/62f4b306844ebd5a4b6f6b21_LOGO%20(2)-p-3200.png 3200w"
							alt="" className="img-logo bot-footer" /></div>
						<div className="content _60">
							<div className="w-layout-grid grid-2">
								<div id="w-node-cafa66b1-d039-27e5-a781-d43e3b22afae-3b22afa9" className="wrapper-nav-footer">
									<h3 className="footer-label">Site</h3><a href="https://www.digitalartclub.io/" className="footer-link alink">home</a><a
										href="https://www.digitalartclub.io/artists" className="footer-link alink">Artists</a><a
											href="https://www.digitalartclub.io/artist/pam-su" className="footer-link alink">On
										Now</a><a href="https://www.digitalartclub.io/club" className="footer-link alink">Club</a><a href="https://www.digitalartclub.io/gallery"
											className="footer-link alink">Gallery</a><a href="https://www.digitalartclub.io/about"
												className="footer-link alink">about</a><a href="https://www.digitalartclub.io/news" className="footer-link alink">News &amp;
													Press</a><a href="https://www.digitalartclub.io/submit-work" className="footer-link alink">Submit Work </a><a
														href="https://www.digitalartclub.io/contact" className="footer-link alink">Contact</a>
								</div>
								<div id="w-node-cafa66b1-d039-27e5-a781-d43e3b22afc6-3b22afa9" className="wrapper-nav-footer">
									<h3 className="footer-label">T&amp;C&#x27;s</h3><a href="https://www.digitalartclub.io/cookie-policy"
										className="footer-link alink">cookie policy</a><a href="https://www.digitalartclub.io/privacy-policy"
											className="footer-link alink">privacy policy</a>
								</div>
								<div id="w-node-cafa66b1-d039-27e5-a781-d43e3b22afbd-3b22afa9" className="wrapper-nav-footer">
									<h3 className="footer-label">Social Links</h3><a href="https://discord.gg/nEQyFEfmaC"
										className="footer-link alink">discord</a><a href="https://twitter.com/digitalart_club?s=11"
											className="footer-link alink">twitter</a><a
												href="https://www.instagram.com/dac_digitalartclub"
												className="footer-link alink">instagram</a>
								</div>
							</div>
						</div>
					</div>
					<div id="w-node-cafa66b1-d039-27e5-a781-d43e3b22afcf-3b22afa9" className="copyright">Copyright © 2022 Digital
						Art Club. All rights reserved.</div>
				</div>
			</div>

		</main>
	);
}

export default App;