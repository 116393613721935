const initialState = {
  loading: false,
  totalSupply: 0,
  maxSupply: 100,

  // whitelist
  wlsupplyLimit: 1,
  wlprice: 0,
  wlMintCount: 0,
  wlmaxLimitPerWallet: 1,
  wlmaxMintAmountPerTx: 1,
  wlMinted: 0,
  wlsaleStatus: false,

  // public
  publicmaxLimitPerWallet: 1,
  publicmaxMintAmountPerTx: 1,
  publicMintCount: 0,
  publicprice: 0,
  publicsupplyLimit: 1,
  publicMinted: 0,
  publicSaleStatus: false,

  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        maxSupply: action.payload.maxSupply,
      
        // whitelist
        wlsupplyLimit: action.payload.wlsupplyLimit,
        wlprice: action.payload.wlprice,
        wlMintCount: action.payload.wlMintCount,
        wlmaxLimitPerWallet: action.payload.wlmaxLimitPerWallet,
        wlmaxMintAmountPerTx: action.payload.wlmaxMintAmountPerTx,
        wlMinted: action.payload.wlMinted,
        wlsaleStatus: action.payload.wlsaleStatus,
      
        // public
        publicmaxLimitPerWallet: action.payload.publicmaxLimitPerWallet,
        publicmaxMintAmountPerTx: action.payload.publicmaxMintAmountPerTx,
        publicMintCount: action.payload.publicMintCount,
        publicprice: action.payload.publicprice,
        publicsupplyLimit: action.payload.publicsupplyLimit,
        publicMinted: action.payload.publicMinted,
        publicSaleStatus: action.payload.publicSaleStatus,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
